import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import styles from "./upcoming-trip.module.scss";
import style from "../signed-user.module.scss";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import moment from "moment";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import {
  myTripsDataRequest,
  myTripsPnrRequest,
  resetPnrDetails,
  retrieveTripsRequest,
  setMyTripDestination,
} from "../../../../slice/myTripsSlice";
import { deleteTripCardDataRequest } from "../../../../slice/deleteTripCardSlice";
import TGIcon from "../../../../../../shared/tg-icon";
import { FlightDetails } from "../flight-details";
import { useNavigate } from "react-router-dom";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import TGModal from "../../../../../../shared/tg-modal";
import { config } from "../../../../../../config/global";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import noUpcomingTripImage from "../../../../../../../public/assets/noUpcomingTrips.svg";

export const UpcomingTrip = (props: { sendData: any; handleTripType: any }) => {
  const { sendData, handleTripType } = props;
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const dispatch = useDispatch();
  const [showNoTrips, setShowNoTrips] = useState(true);
  const [cardBorder, setCardBorder] = useState(true);
  const IMAGE_API = process.env.REACT_APP_TG_IMAGE_SEARCH_URL;
  const [showTripDetails, setShowTripDetails] = useState("");
  const [pnrRequest, setPnrRequest] = useState<any>();
  const [showDeleteCard, setShowDeleteCard] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [deletePnr, setDeletePnr] = useState("");
  const navigate = useNavigate();
  const upcomingCardRef = useRef<(HTMLDivElement | null)[]>([]);
  const upcomingTripsResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips.myTripsDetails
  );
  const addTripResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips
  );
  const deleteTripsResponse = useSelector(
    (state: RootState) => state?.osciReducer?.deleteTripCard
  );

  useEffect(() => {
    dispatch(
      myTripsDataRequest({
        category: "upcoming",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (upcomingTripsResponse?.pnrDetails?.length > 0) {
      setShowNoTrips(false);
    } else {
      setShowNoTrips(true);
    }
  }, [upcomingTripsResponse]);

  useEffect(() => {
    if (deleteTripsResponse.hasData == true) {
      dispatch(
        myTripsDataRequest({
          category: "upcoming",
        })
      );
    }
  }, [deleteTripsResponse.hasData]);

  useEffect(() => {
    if (addTripResponse.isAddTripSuccess == true) {
      dispatch(
        myTripsDataRequest({
          category: "upcoming",
        })
      );
    }
  }, [addTripResponse.isAddTripSuccess]);

  function NoupcomingTrips() {
    return (
      <div
        className={
          isDesktopView ? style.imageContainer : style.imageContainerMobile
        }
      >
        <img src={noUpcomingTripImage} alt="No Upcoming Trips" />
        <p className={style.imgText}>{t("label_no_upcoming_trips")}</p>
      </div>
    );
  }

  const showFlightDetails = (index: any, pnrData: any) => {
    dispatch(resetPnrDetails());
    setCardBorder(!cardBorder);
    setShowTripDetails(showTripDetails === index ? null : index);
    setInitialLoad(false);
    let requestData = {
      pnr: pnrData.pnr,
      lastName: pnrData.lastName,
      pastTrip: false,
      isUpcoming: false,
    };
    setPnrRequest(requestData);
    sendData(pnrData);
    handleTripType("Upcoming");
  };

  const hideFlightDetails = (index: any) => {
    setCardBorder(!cardBorder);
    setShowTripDetails(showTripDetails === index ? null : index);
    const selectedEle = document.getElementById(index);
    selectedEle?.scrollIntoView({ behavior: "smooth", block: "start" });
    sendData({});
  };

  const showDeletedTripData = (detail: any) => {
    setShowDeleteCard(true);
    setDeletePnr(detail);
  };
  const handleCloseModal = () => {
    setShowDeleteCard(false);
    setDeletePnr("");
  };
  const deleteTripCardHandler = () => {
    setShowDeleteCard(false);
    dispatch(deleteTripCardDataRequest(deletePnr));
    setDeletePnr("");
  };
  const handleMobileNavigate = (pnrData: any) => {
    dispatch(resetPnrDetails());
    const data =
      pnrData?.tripType == "MULTI_CITY"
        ? renderMultiCityAirportCode(pnrData)
        : pnrData.flightDetails?.[0]?.destination;
    dispatch(setMyTripDestination(data));
    let requestData = {
      pnr: pnrData.pnr,
      lastName: pnrData.lastName,
      pastTrip: false,
      isUpcoming: false,
    };
    navigate(
      `/${i18n.language}/my-trips-details/${
        pnrData?.tripType == "MULTI_CITY"
          ? renderMultiCityAirportCode(pnrData)?.replace(/ \/\/ /g, "-")
          : pnrData.flightDetails?.[0]?.destination
      }`,
      {
        state: { requestData, data: data },
      }
    );
  };

  const handleManageBooking = (pnrData: any) => {
    const manageBookingUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.MANAGE_BOOKING_API;
    const lang = i18n?.language?.split("-")?.[0];
    const manageBookingQueryParams =
      "booking_no=" +
      pnrData.pnr +
      "&lastname=" +
      pnrData.lastName +
      "&lang=" +
      lang;

    const amadeusRedirectionUrl =
      manageBookingUrl + "?" + manageBookingQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  const renderMultiCityAirportCode = (item: any) => {
    //contains flightRef of connecting flights
    const flight = [];
    //Contains segmentGroups Length
    const flightLength = [];
    let content = "";
    //Contains segmentGroups last flightRef
    let lastArray = [];

    //Iteration for to push elements in an flight array and flightLength array
    for (let i: number = 0; i < item?.segmentGroups.length; i++) {
      flightLength.push(
        JSON.parse(item?.segmentGroups[i].connectionSegments).length + 1
      );
      for (
        let j: number = 0;
        j < JSON.parse(item?.segmentGroups[i]?.connectionSegments).length;
        j++
      ) {
        flight.push(JSON.parse(item?.segmentGroups[i]?.connectionSegments)[j]);
      }
    }

    //Iteration for to push elements in an lastArray
    for (let last: number = 0; last < item?.segmentGroups.length; last++) {
      lastArray.push(
        JSON.parse(item?.segmentGroups[last].connectionSegments).reverse()[0]
      );
    }
    let startVal = 0;
    let count = 0;

    //condition for checking flightDetails flightRef number with flight array elements
    for (let k: number = 0; k < item?.flightDetails.length; k++) {
      if (Number(item?.flightDetails[k]?.flightRef) === flight[count]) {
        if (lastArray[startVal] === Number(item?.flightDetails[k]?.flightRef)) {
          if (
            item?.flightDetails[k]?.flightRef ===
            item?.flightDetails.length.toString()
          ) {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
          } else {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode} // `;
          }
          startVal++;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-`;
        }

        count++;
      } else {
        if (
          item?.flightDetails[k]?.flightRef ===
          item?.flightDetails.length.toString()
        ) {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode} // `;
        }
      }
    }
    return content;
  };

  const renderMultiCityAirportName = (flights: any): any => {
    const result: any = [];
    flights.forEach((flight: any, index: any) => {
      if (result[result.length - 1] !== flight.departureAirportName) {
        result.push(flight.departureAirportName);
      }
      if (
        index === flights.length - 1 ||
        result[result.length - 1] !== flight.arrivalAirportName
      ) {
        result.push(flight.arrivalAirportName);
      }
    });
    return result;
  };

  const handleLazyLoad = (cardId: any) => {
    const pnr = upcomingTripsResponse?.pnrDetails[cardId]?.pnr;
    const lastName = upcomingTripsResponse?.pnrDetails[cardId]?.lastName;
    const pastTrip = false;
    dispatch(retrieveTripsRequest({ pnr, lastName, pastTrip, cardId }));
  };

  useEffect(() => {
    if (!showNoTrips) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index: any = entry.target.getAttribute("data-index");
              if (
                upcomingTripsResponse?.pnrDetails[index]?.flightDetails
                  ?.length === 0
              ) {
                handleLazyLoad(index);
              }
            }
          });
        },
        { threshold: 0.1 }
      );

      upcomingCardRef.current.forEach((card) => {
        if (card) {
          observer.observe(card);
        }
      });

      return () => {
        upcomingCardRef.current.forEach((card) => {
          if (card) {
            observer.unobserve(card);
          }
        });
      };
    }
  }, [upcomingTripsResponse, upcomingCardRef, showNoTrips]);

  useEffect(() => {
    if (
      initialLoad &&
      upcomingTripsResponse?.pnrDetails?.length > 0 &&
      window.innerWidth > 767
    ) {
      showFlightDetails(0, upcomingTripsResponse?.pnrDetails[0]);
    }
  }, [upcomingTripsResponse]);

  if (!addTripResponse?.myTripsDetails?.hasOwnProperty("memberId")) {
    return (
      <div>
        <Modal
          show={true}
          centered
          className={`${styles["my-trips-loader-container"]} my-trips-main-loading-spinner`}
        >
          <ModalBody
            style={{
              width: "max-content",
              margin: "auto",
              background: "unset",
              border: "unset",
            }}
          >
            <Spinner animation="border" variant="primary" /> <br />
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <>
        {showNoTrips ? (
          <div className={style.noTripIcon}>
            <NoupcomingTrips />
          </div>
        ) : (
          <div className={styles.upcomingTripCardContainerScroll}>
            {upcomingTripsResponse?.pnrDetails?.map((resp: any, index: any) => {
              const loading =
                addTripResponse?.isLoading && resp?.flightDetails?.length === 0;
              return (
                <div
                  className={styles.upcomingTripCardContainer}
                  id={index}
                  key={index}
                  ref={(el) => (upcomingCardRef.current[index] = el)}
                  data-index={index}
                >
                  {/* Flight Card Section */}
                  <div
                    className={`${styles["upcoming-trip-background-card"]} ${
                      upcomingTripsResponse?.pnrDetails?.length > 0
                        ? showTripDetails !== index
                          ? styles["show-trip-border"]
                          : styles["hide-trip-border"]
                        : styles["hide-trip-border"]
                    }`}
                    style={{
                      background: `linear-gradient(26deg, rgba(33, 9, 67, 0.90) 4.24%, rgba(33, 9, 67, 0.00) 90.99%), url(${
                        resp?.tripType === "MULTI_CITY"
                          ? "../assets/multiCity.svg"
                          : IMAGE_API +
                            resp?.flightDetails[resp.flightDetails.length - 1]
                              ?.arrivalCode +
                            ".jpg"
                      }) lightgray 0px -273.514px / 100% 205.405% no-repeat`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    {resp.addedTrip && (
                      <div
                        className={styles.cardContentCloseIcon}
                        onClick={() => {
                          showDeletedTripData(resp?.pnr);
                        }}
                      >
                        <TGIcon
                          icon="close-icon"
                          size={""}
                          fillColor={"#FFFFFF"}
                        />
                      </div>
                    )}
                    {loading ? (
                      <div className={styles.shimmerCardContent}>
                        <span
                          className={`${styles.shimmer} ${styles.shimmerTripToHeader}`}
                        ></span>
                        <span
                          className={`${styles.shimmer} ${styles.shimmerDestinationHeader}`}
                        ></span>
                        <div className={styles.dateContainer}>
                          <span
                            className={`${styles.shimmer} ${styles.shimmerDate}`}
                          ></span>
                          <span className={styles.bookingRefNo}>
                            {t("label_booking_ref")}: {resp.pnr}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.cardContent}>
                        <span className={styles.tripToHeader}>
                          <span className={styles.tripToHeaderSub}>
                            {resp?.tripType == "ROUND"
                              ? t("label_book_widget_round_trip")
                              : resp?.tripType == "MULTI_CITY"
                              ? t("label_trip_multicity")
                              : t("label_book_widget_one_way")}
                          </span>
                          <span>{t("label_trip_to")}</span>
                        </span>
                        <span className={styles.destinationHeader}>
                          {resp?.tripType == "MULTI_CITY"
                            ? renderMultiCityAirportCode(resp)
                            : resp.flightDetails?.[0]?.destination}
                        </span>
                        <div className={styles.dateContainer}>
                          <span className={styles.date}>
                            {`${moment(
                              resp?.flightDetails[0]?.depatureDate,
                              "DD-MM-YY HH:mm"
                            ).format("DD MMM YYYY")} - ${moment(
                              resp?.flightDetails[resp.flightDetails.length - 1]
                                ?.depatureDate,
                              "DD-MM-YY HH:mm"
                            ).format("DD MMM YYYY")}`}
                          </span>
                          <span className={styles.bookingRefNo}>
                            {t("label_booking_ref")}: {resp.pnr}
                          </span>
                        </div>
                      </div>
                    )}
                    <div
                      className={`${styles["trip-detail"]} ${
                        cardBorder
                          ? styles.addPaddingBottom
                          : styles.removePaddingBottom
                      }`}
                    >
                      <div className={styles["trip-detail-content"]}>
                        <div className={styles["trip-detail-content-inner"]}>
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripType}`}
                            ></div>
                          ) : (
                            <div className={styles["trip-type"]}>
                              {resp?.tripType == "ROUND"
                                ? t("label_book_widget_round_trip")
                                : resp?.tripType == "MULTI_CITY"
                                ? t("label_trip_multicity")
                                : t("label_book_widget_one_way")}
                            </div>
                          )}
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripLocation}`}
                            ></div>
                          ) : (
                            <div className={styles["trip-location"]}>
                              {resp?.tripType == "ROUND"
                                ? `${resp?.flightDetails[0]?.origin} - ${resp?.flightDetails[0]?.destination}`
                                : resp?.tripType === "MULTI_CITY"
                                ? renderMultiCityAirportCode(resp)
                                : `${resp?.flightDetails[0]?.origin} - ${
                                    resp?.flightDetails[
                                      resp.flightDetails.length - 1
                                    ]?.destination
                                  }`}
                            </div>
                          )}
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripAirportName}`}
                            ></div>
                          ) : (
                            <div className={styles["trip-airportName"]}>
                              {resp?.tripType === "ONE_WAY" &&
                                `${resp?.flightDetails[0]?.arrivalAirportName} - ${resp?.flightDetails[0]?.departureAirportName}`}
                              {resp?.tripType === "ROUND" &&
                                `${
                                  resp?.flightDetails[0]?.arrivalAirportName
                                } - ${
                                  resp?.flightDetails[
                                    resp.flightDetails.length - 1
                                  ]?.arrivalAirportName
                                }`}
                              {resp?.tripType === "MULTI_CITY" &&
                                resp?.flightDetails &&
                                renderMultiCityAirportName(
                                  resp?.flightDetails
                                )?.map((item: any, index: any, data: any) => (
                                  <span>
                                    {item}{" "}
                                    {data.length - 1 !== index ? "-" : ""}{" "}
                                  </span>
                                ))}
                            </div>
                          )}
                        </div>
                        {loading ? (
                          <div
                            className={`${styles.shimmer} ${styles.shimmerTripDetailBtn}`}
                          ></div>
                        ) : (
                          <div
                            className={styles["trip-detail-btn"]}
                            onClick={() => handleManageBooking(resp)}
                          >
                            {t("label_book_widget_manage_booking")}
                          </div>
                        )}
                      </div>
                      {!isDesktopView && (
                        <>
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripDetailBtnView}`}
                            ></div>
                          ) : (
                            <div
                              className={styles["trip-detail-btn-view"]}
                              onClick={() => handleMobileNavigate(resp)}
                            >
                              {t("label_view_detail")}
                            </div>
                          )}
                        </>
                      )}
                      {showTripDetails !== index && isDesktopView && (
                        <div className={`${styles["trip-detail-button"]}`}>
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripDetailShow}`}
                            ></div>
                          ) : (
                            <div
                              className={styles["trip-detail-btn-content"]}
                              onClick={() => {
                                setInitialLoad(false);
                                showFlightDetails(index, resp);
                              }}
                            >
                              <span className={styles["trip-detail-show"]}>
                                {t("label_my_trips_show_trips")}
                              </span>
                              <TGIcon
                                icon="trip-uparrow-icon"
                                size={""}
                                fillColor={""}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Flight Details Section */}
                  {showTripDetails === index && (
                    <div className={styles.flightDetailsSection}>
                      <div className={styles.flightDetailsContent}>
                        <div className={styles.flightDetails}>
                          <div className={styles.flightDetailsHeader}>
                            <span className={styles.flightDetailsHeaderText}>
                              {t("label_redemption_flight_details")}
                            </span>
                            {isDesktopView && (
                              <div className={styles.eLibraryButtonContainer}>
                                <div className={styles.eLibraryButtonContent}>
                                  <div className={styles.eLibraryButton}>
                                    <span className={styles.eLibraryButtonText}>
                                      {t("label_eLibrary_button")
                                        .charAt(0)
                                        .toLowerCase() +
                                        t("label_eLibrary_button").slice(1)}
                                    </span>
                                    <TGIcon
                                      icon="e-library-icon"
                                      fillColor={"#684B9B"}
                                      size={""}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {isDesktopView && (
                            <FlightDetails
                              flightDetails={[resp, index]}
                              selectedTab="UP"
                              requestData={pnrRequest}
                              parentIndex={index}
                            />
                          )}
                        </div>
                        <div className={styles.hideTripContainer}>
                          <div className={styles.hideTripButtonContent}>
                            <div
                              className={styles["hide-trip-button"]}
                              onClick={() => {
                                setInitialLoad(false);
                                hideFlightDetails(index);
                              }}
                            >
                              <span className={styles["hide-trip-button-text"]}>
                                {t("label_my_trips_hide_trips")}
                              </span>
                              <TGIcon
                                icon="trip-downarrow-icon"
                                size={""}
                                fillColor={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <TGModal
                    show={showDeleteCard}
                    handleClose={handleCloseModal}
                    centered={true}
                    customModalClass={styles["trip-card-model"]}
                    customOffCanvasClass={styles["trip-card-offcanvas"]}
                    backdropClassName={styles["trip-card-backdrop"]}
                  >
                    <div className={styles.tcModalFlex}>
                      <div className={styles.tcModal}>
                        <img
                          className={styles.deletetripimage}
                          src="../assets/DeleteTripImage.png"
                          alt="deletetripimage"
                        />
                        <div className={styles.modalContent}>
                          <span className={styles.modalHeading}>
                            {t("delete_trip_heading")}
                          </span>
                          <div className={styles.modalDescription}>
                            {t("delete_trip_description")}
                          </div>
                        </div>
                      </div>
                      <div className={styles.dbuttonContainer}>
                        <TGButtonVariants
                          label={t("button_label_keep")}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="30px"
                          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                          bgColor="#F5EFFF"
                          bgColorHover="#F5EFFF"
                          padding="8px 24px"
                          textColor="#381B6B"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="160%"
                          height="50px"
                          onClick={handleCloseModal}
                          borderHover="none"
                          width={isDesktopView ? "170px" : "50%"}
                        />
                        <TGButtonVariants
                          label={t("button_label_delete")}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="30px"
                          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                          bgColor="#381B6B"
                          bgColorHover="#381B6B"
                          padding="8px 24px"
                          textColor="#FFFFFF"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="160%"
                          height="50px"
                          onClick={deleteTripCardHandler}
                          borderHover="none"
                          width={isDesktopView ? "170px" : "50%"}
                        />
                      </div>
                    </div>
                  </TGModal>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }
};
