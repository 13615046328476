import React, { useEffect, useState } from "react";
import styles from "./mobile-flight-details.module.scss";
import TGIcon from "../../../../../../shared/tg-icon";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  myTripsPnrRequest,
  setMyTripDestination,
} from "../../../../slice/myTripsSlice";
import { RootState } from "../../../../slice/RootReducer";
import moment from "moment";
import TGButton from "../../../../../../shared/tg-button";
import {
  getEReceiptDataRequest,
  resetEreceiptDataRequest,
} from "../../../../slice/eReceiptSlice";
import { config } from "../../../../../../config/global";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import MobCheckIn from "./mob-checkIn";
import { Modal, ModalBody, Spinner } from "react-bootstrap";

const MobFlightDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { requestData, data } = location.state;
  const [activeTab, setActiveTab] = useState(0);
  const [flightDetailsList, setFlightDetailsList] = useState<any>();
  const [singleFlightDetails, setSingleFlightDetails] = useState<any>();
  const [showAllPassengers, setShowAllPassengers] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const flightDetailsResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips.pnrDetails
  );

  const { eReceiptBlob, error } = useSelector(
    (state: RootState) => state?.osciReducer?.eReceipt
  );

  useEffect(() => {
    dispatch(myTripsPnrRequest(requestData));
    dispatch(setMyTripDestination(data));
  }, [data]);

  useEffect(() => {
    if (flightDetailsResponse?.segmentGroupList?.length == 0) {
      setFlightDetailsList(flightDetailsResponse);
      setSingleFlightDetails(flightDetailsResponse?.flightInformationList[0]);
    } else {
      const mergedArray = flightDetailsResponse?.segmentGroupList?.map(
        (item: any) => {
          const indices = JSON.parse(item.connectionSegments);
          const noOfStop = indices.length - 1;
          const firstObject =
            flightDetailsResponse?.flightInformationList[indices[0] - 1];
          const secondObject =
            flightDetailsResponse?.flightInformationList[indices[noOfStop] - 1];
          const thirdObject =
            indices.length > 2
              ? flightDetailsResponse?.flightInformationList[noOfStop - 1]
              : {};
          let firstTripDurationsplit = firstObject.tripDuration.split(" ");
          let secondTripDurationSplit = secondObject.tripDuration.split(" ");
          let firstTotalMinutes =
            Number(firstTripDurationsplit[0]) * 60 +
            Number(firstTripDurationsplit[2]);
          let secondTotalMinutes =
            Number(secondTripDurationSplit[0]) * 60 +
            Number(secondTripDurationSplit[2]);
          let totalAddedMinutes = firstTotalMinutes + secondTotalMinutes;
          let totalHours = Math.floor(totalAddedMinutes / 60);
          let totalMinutes = totalAddedMinutes % 60;
          const totalDuration = `${totalHours} hr ${totalMinutes} min`;
          return {
            departureCode: firstObject.departureCode,
            arrivalCode: secondObject.arrivalCode,
            departureCity: firstObject.departureCity,
            arrivalCity: secondObject.arrivalCity,
            departureAirport: firstObject.departureAirport,
            arrivalAirport: secondObject.arrivalAirport,
            numberOfStops: noOfStop,
            departureDate: firstObject.departureDate,
            arrivalDate: secondObject.arrivalDate,
            departureTime: firstObject.departureTime,
            arrivalTime: secondObject.arrivalTime,
            bookingClass: secondObject.bookingClass,
            departureDateTimeUtc: firstObject.departureDateTimeUtc,
            tripDuration: totalDuration,
            arrivalDateTimeUtc: secondObject.arrivalDateTimeUtc,
            airCarrier: [firstObject.airCarrier, secondObject.airCarrier],
            flightNumber: secondObject.flightNumber,
            departureTerminal: firstObject.departureTerminal,
            arrivalTerminal: secondObject.arrivalTerminal,
            flightRef: indices,
            flightStatus: secondObject.flightStatus,
            flightLegInfo: secondObject.flightLegInfo,
            standardBaggageAllowance: secondObject.standardBaggageAllowance,
            stopArray:
              Object.keys(thirdObject).length > 0
                ? [firstObject, thirdObject, secondObject]
                : [firstObject, secondObject],
            showPassenger: false,
            showStop: false,
          };
        }
      );
      // after merging connection segment need to push the remaining flightlist to final array
      const flightRefInMergedArray = new Set(
        mergedArray?.flatMap((item: any) => item?.flightRef)
      );
      const elementsToAdd =
        flightDetailsResponse?.flightInformationList?.filter(
          (item: any) => !flightRefInMergedArray.has(Number(item?.flightRef))
        );
      const finalArray = mergedArray?.concat(elementsToAdd);
      // connection segments has unordered then need to sort so below fn needed
      const sortFlightList = finalArray?.sort((a: any, b: any) => {
        const firstValue = Array.isArray(a?.flightRef)
          ? Math.min(...a?.flightRef)
          : Number(a?.flightRef);
        const secondValue = Array.isArray(b?.flightRef)
          ? Math.min(...b?.flightRef)
          : Number(b?.flightRef);
        return firstValue - secondValue;
      });
      setFlightDetailsList({
        ...flightDetailsResponse,
        flightInformationList: sortFlightList,
      });
      setSingleFlightDetails(sortFlightList ? sortFlightList[0] : "");
    }
  }, [flightDetailsResponse]);

  const handleDayConversion = (date: any) => {
    let dayConversion = moment(date, "DD-MMM-YYYY")
      .format("dddd")
      .substring(0, 3);
    return dayConversion;
  };

  const tripDurationConversion = (arrivalTime: any, departureTime: any) => {
    let tripDuration = moment
      .utc(moment(arrivalTime, "HHmm").diff(moment(departureTime, "HHmm")))
      .format("HH : mm");
    let splitDuration = tripDuration.split(":");
    splitDuration[0] = splitDuration[0].trim() + "h";
    splitDuration[1] = splitDuration[1] + "min";
    return splitDuration.join(" : ");
  };

  const passengersToShow = showAllPassengers
    ? flightDetailsList?.paxInfo
    : flightDetailsList?.paxInfo?.slice(0, 1);

  const handleEReceiptNavigation = (ereceiptRequest: any) => {
    dispatch(resetEreceiptDataRequest());
    window.scrollTo(0, 0);
    let eReceiptUrl =
      process.env.REACT_APP_TG_API_BASE_URL + config.E_RECEIPT_API;
    let eReceiptParams = `pnrOrTicketNo=${ereceiptRequest.ticketno}&firstName=${ereceiptRequest.firstName}&lastName=${ereceiptRequest.lastName}`;
    const printeReceiptnUrl = eReceiptUrl + "?" + eReceiptParams;
    dispatch(
      getEReceiptDataRequest({
        url: printeReceiptnUrl,
        profileInfo: {
          firstName: ereceiptRequest.firstName,
          lastName: ereceiptRequest.lastName,
          bookingRef: ereceiptRequest.bookingRef,
        },
      })
    );
  };

  useEffect(() => {
    if (eReceiptBlob) {
      navigate(`/${i18n.language}/e-Receipt/success`);
    } else if (error) {
      navigate(`/${i18n.language}/e-Receipt/error`);
    }
  }, [eReceiptBlob, error]);

  const handleManageBooking = () => {
    const manageBookingUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.MANAGE_BOOKING_API;
    const lang = i18n?.language?.split("-")?.[0];
    const manageBookingQueryParams =
      "booking_no=" +
      flightDetailsList?.pnr +
      "&lastname=" +
      flightDetailsList?.lastName +
      "&lang=" +
      lang;

    const amadeusRedirectionUrl =
      manageBookingUrl + "?" + manageBookingQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  if (!flightDetailsResponse?.hasOwnProperty("flightInformationList")) {
    return (
      <div>
        <Modal
          show={true}
          centered
          className={`${styles["mobile-my-trips-loader-container"]} mobile-my-trips-main-loading-spinner`}
        >
          <ModalBody
            style={{
              width: "max-content",
              margin: "auto",
              background: "unset",
              border: "unset",
            }}
          >
            <Spinner animation="border" variant="primary" /> <br />
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <div className={styles.mobileTabContentHeader}>
          {flightDetailsList?.flightInformationList?.map(
            (data: any, index: any) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setActiveTab(index);
                    setSingleFlightDetails(data);
                  }}
                  className={`${
                    activeTab === index && styles["mytrips-mobile-tab-active"]
                  }`}
                >
                  {data?.departureCode}-{data?.arrivalCode}
                </div>
              );
            }
          )}
        </div>
        <div className={styles.mobileflightScrollMain}>
          <div className={styles.flightDetailsMain}>
            <div className={styles.flightDetailDateMain}>
              <div className={styles.flightDetailDate}>{`${handleDayConversion(
                singleFlightDetails?.departureDate
              )} ${", "} ${moment(
                singleFlightDetails?.departureDate,
                "DD-MMM-YYYY"
              ).format("DD MMM YYYY")}`}</div>
              <div className={styles.mobileScheduledMain}>
                -
                {/* <TGIcon icon="scheduled-point" size={""} fillColor={"#006723"} />
              <span className={styles.mobileScheduledText}>
                {t("label_my_trips_scheduled")}
              </span> */}
              </div>
            </div>
            <MobCheckIn
              flightDetailsList={flightDetailsList}
              singleFlightDetails={singleFlightDetails}
            />
            <div className={styles.eLibraryMain}>
              <div className={styles.flightDetailDate}>
                {t("label_redemption_flight_details")}
              </div>
              {!requestData?.pastTrip && (
                <div className={styles.eLibraryMainBtn}>
                  <div className={styles.eLibraryMainName}>
                    {t("label_eLibrary_button").charAt(0).toLowerCase() +
                      t("label_eLibrary_button").slice(1)}
                  </div>
                  <TGIcon
                    icon="e-library-icon"
                    size={""}
                    fillColor={"#684B9B"}
                  />
                </div>
              )}
            </div>
            <div className={styles.mobileTripDurationMainSeperate}>
              <div className={styles.mobileTripDurationMain}>
                <div className={styles.mobileTripDurationTime}>
                  {t("label_my_trips_trip_duration")}{" "}
                  {singleFlightDetails?.tripDuration === null
                    ? tripDurationConversion(
                        singleFlightDetails?.arrivalTime,
                        singleFlightDetails?.departureTime
                      )
                    : singleFlightDetails?.tripDuration}
                </div>
                {singleFlightDetails?.numberOfStops === 0 ||
                singleFlightDetails?.numberOfStops === null ? (
                  ""
                ) : (
                  <div className={styles.stopMobileTextMain}>
                    <TGIcon icon="stop-point" size={""} fillColor={"#FF5C7C"} />
                    <span className={styles.mobileTripDurationTime}>
                      {singleFlightDetails?.numberOfStops}{" "}
                      {t("label_redemption_stop")}
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.flightFullDetails}>
                <div className={styles.flightFullDetailsTimeMain}>
                  <div>
                    <div className={styles.flightFullDetailsTime}>
                      {singleFlightDetails?.departureTime}
                    </div>
                    <div className={styles.flightFullDetailsTime}>
                      {singleFlightDetails?.departureCode}
                    </div>
                    <div className={styles.flightFullDetailsCountry}>
                      {singleFlightDetails?.departureCity}
                    </div>
                  </div>
                  <div className={styles.mobileHourTextMain}>
                    <div className={styles.mobileHourText}>
                      {singleFlightDetails?.tripDuration === null
                        ? tripDurationConversion(
                            singleFlightDetails?.arrivalTime,
                            singleFlightDetails?.departureTime
                          )
                        : singleFlightDetails?.tripDuration}
                    </div>
                    <div className={styles.mobileFlightLineMain}>
                      <span className={styles.mobileFlightLine}></span>
                      <TGIcon icon="flight-icon" size={""} fillColor={""} />
                      <span className={styles.mobileFlightLine}></span>
                    </div>
                    <div className={styles.mobileHourText}>
                      {singleFlightDetails?.numberOfStops === 0 ||
                      singleFlightDetails?.numberOfStops === null
                        ? t("label_nonstop")
                        : singleFlightDetails?.numberOfStops +
                          " " +
                          t("label_redemption_stop")}
                    </div>
                  </div>
                  <div className={styles.timeStopTextMobileSuperMain}>
                    <div className={styles.timeStopTextMobileMain}>
                      <span className={styles.flightFullDetailsTime}>
                        {singleFlightDetails?.arrivalTime}
                      </span>
                      {singleFlightDetails?.numberOfStops === 0 ||
                      singleFlightDetails?.numberOfStops === null ? (
                        ""
                      ) : (
                        <span className={styles.timeStopTextMobile}>
                          +{singleFlightDetails?.numberOfStops}
                        </span>
                      )}
                    </div>
                    <div className={styles.flightFullDetailsTime}>
                      {singleFlightDetails?.arrivalCode}
                    </div>
                    <div className={styles.flightFullDetailsCountry}>
                      {singleFlightDetails?.arrivalCity}
                    </div>
                  </div>
                </div>
                <div className={styles.mobileTerminalMain}>
                  <div>
                    <div className={styles.mobileTerminalSubCommon}>
                      {singleFlightDetails?.departureAirport}
                    </div>
                    <div className={styles.mobileTerminalSubCommon}>
                      {t("label_redemption_termainal")}{" "}
                      {singleFlightDetails?.departureTerminal !== null
                        ? singleFlightDetails?.departureTerminal
                        : "--"}
                    </div>
                  </div>
                  <div>
                    <div
                      className={`${styles.mobileTerminalSubCommon} ${styles.mobileTerminalSubTextAlign}`}
                    >
                      {singleFlightDetails?.arrivalAirport}
                    </div>
                    <div
                      className={`${styles.mobileTerminalSubCommon} ${styles.mobileTerminalSubTextAlign}`}
                    >
                      {t("label_redemption_termainal")}{" "}
                      {singleFlightDetails?.arrivalTerminal !== null
                        ? singleFlightDetails?.arrivalTerminal
                        : "--"}
                    </div>
                  </div>
                </div>
                {singleFlightDetails &&
                  singleFlightDetails.hasOwnProperty("stopArray") &&
                  singleFlightDetails?.stopArray?.map(
                    (data: any, index: any, stopArray: any) => (
                      <div className={styles.stopFlightDetailsMain} key={index}>
                        <div className={styles.stopFlightDetailsMainContent}>
                          <div className={styles.travelLineContainer}>
                            <TGIcon icon="travel-icon" fillColor="" />
                          </div>
                          <div
                            className={
                              styles.stopFlightDetailsMainContentSingle
                            }
                          >
                            <div
                              className={styles.stopFlightDetailsTimeSingleMain}
                            >
                              <div
                                className={styles.stopFlightDetailsTimeSingle}
                              >
                                <span className={styles.flightFullDetailsTime}>
                                  {data?.departureTime}
                                </span>
                                <span className={styles.flightFullDetailsTime}>
                                  {data?.departureCity} ({data?.departureCode})
                                </span>
                              </div>
                              <div className={styles.airportNameTextMain}>
                                <span className={styles.airportNameText}>
                                  {data?.departureAirport}
                                </span>
                                <TGIcon icon="circle-icon" fillColor="" />
                                <span className={styles.airportNameText}>
                                  {t("label_redemption_termainal")}{" "}
                                  {data?.departureTerminal === ""
                                    ? "--"
                                    : data?.departureTerminal}
                                </span>
                              </div>
                            </div>
                            <div className={styles.timeDiffer}>
                              {data?.tripDuration}
                            </div>
                            <div
                              className={styles.stopFlightDetailsTimeSingleMain}
                            >
                              <div
                                className={styles.stopFlightDetailsTimeSingle}
                              >
                                <span className={styles.flightFullDetailsTime}>
                                  {data?.arrivalTime}
                                </span>
                                <span className={styles.flightFullDetailsTime}>
                                  {data?.arrivalCity} ({data?.arrivalCode})
                                </span>
                              </div>
                              <div className={styles.airportNameTextMain}>
                                <span className={styles.airportNameText}>
                                  {data?.arrivalAirport}
                                </span>
                                <TGIcon icon="circle-icon" fillColor="" />
                                <span className={styles.airportNameText}>
                                  {t("label_redemption_termainal")}{" "}
                                  {data?.arrivalTerminal === ""
                                    ? "--"
                                    : data?.arrivalTerminal}
                                </span>
                              </div>
                            </div>
                            <div className={styles.thaiIconFlightMain}>
                              <div className={styles.thaiIconFlightSubMain}>
                                <div className={styles.thaiIconFlight}>
                                  <TGIcon
                                    icon="star-alliance-icon"
                                    fillColor=""
                                    size=""
                                  />
                                  <div className={styles.airportNameText}>
                                    {data?.flightLegInfo[0]?.flightOwner
                                      ?.split(" ")
                                      .slice(2)
                                      .join(" ")}
                                  </div>
                                </div>
                                <div className={styles.thaiIconFlight}>
                                  <TGIcon
                                    icon="circle-icon"
                                    fillColor="#2C3059"
                                  />
                                  <div className={styles.airportNameText}>
                                    {data?.flightNumber}
                                  </div>
                                </div>
                              </div>
                              <div className={styles.timeDiffer}>
                                {data?.airCarrier}
                              </div>
                            </div>
                          </div>
                        </div>
                        {singleFlightDetails?.stopArray?.length - 1 !==
                          index && (
                          <div
                            className={`${styles.layoverMain} ${styles.airportNameText}`}
                          >
                            {moment
                              .utc(
                                moment(
                                  stopArray[index + 1]?.departureTime,
                                  "HHmm"
                                ).diff(moment(data?.arrivalTime, "HHmm"))
                              )
                              .format("h [hr] m [min]")}{" "}
                            {data?.arrivalAirport}
                          </div>
                        )}
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
          <div className={styles.passengerMainContianer}>
            <div className={styles.flightDetailDate}>
              {t("label_my_trips_passengers")}
            </div>
            <div className={styles.flightFullPassenger}>
              {/* <div className={styles.flightNotCheckIn}>Not Check In</div> */}
              <div className={styles.flightNotCheckIn}>-</div>
              {passengersToShow?.map((person: any, index: any) => (
                <div className={styles.flightNotCheckInMain} key={index}>
                  <div>
                    <div className={styles.flightCheckInName}>
                      {person?.firstName} {person?.lastName} {person?.title}
                    </div>
                    <div className={styles.flightTicketNo}>
                      {t("label_ticket_number")}:{" "}
                      {person?.ticketno?.replace(/-/g, "")}
                    </div>
                    {requestData?.pastTrip && (
                      <div
                        className={styles.mobGetReceipt}
                        onClick={() => handleEReceiptNavigation(person)}
                      >
                        {t("label_my_trips_ereceipt")}
                      </div>
                    )}
                  </div>
                  <div className={styles.inclusionMain}>
                    <TGIcon icon="my-trip-seat-icon" fillColor="" />
                    <span className={styles.flightTicketNo}>
                      {person?.serviceInfo !== null
                        ? person?.serviceInfo[0]?.seatNo !== null
                          ? person?.serviceInfo[0]?.seatNo
                          : "-"
                        : "-"}
                    </span>
                  </div>
                  <div className={styles.inclusionMain}>
                    <TGIcon icon="my-trip-baggage-icon" fillColor="" />
                    <div>
                      <div className={styles.flightTicketNo}>
                        {person?.serviceInfo !== null
                          ? person?.serviceInfo[0]?.baggage !== null
                            ? `${person?.serviceInfo[0]?.baggage} ${t(
                                "label_standard"
                              )}`
                            : t("label_my_trips_not_selected")
                          : t("label_my_trips_not_selected")}
                      </div>
                      {/* <div className={styles.flightAllowance}>
                      +10 KG Additional Baggage Allowance
                    </div> */}
                    </div>
                  </div>
                  <div className={styles.inclusionMain}>
                    <TGIcon icon="my-trip-cutlery-icon" fillColor="" />
                    <div>
                      <div className={styles.flightTicketNo}>
                        <div className={styles.flightTicketNo}>
                          {person?.serviceInfo !== null
                            ? person?.serviceInfo[0]?.meal !== null
                              ? `${person?.serviceInfo[0]?.meal}`
                              : t("label_my_trips_not_selected")
                            : t("label_my_trips_not_selected")}
                        </div>
                      </div>
                      {/* {!requestData?.pastTrip && (
                      <div className={styles.editMealsMain}>
                        <div className={styles.eLibraryMainName}>
                          Edit Selected Meals
                        </div>
                        <TGIcon
                          icon="arrow-right-sm"
                          size={""}
                          fillColor={"#684B9B"}
                        />
                      </div>
                    )} */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {flightDetailsList?.paxInfo?.length > 1 && (
              <div
                className={styles.mobileAdditionalMain}
                onClick={() => setShowAllPassengers((prev) => !prev)}
              >
                <div className={styles.mobileAdditional}>
                  {showAllPassengers
                    ? t("label_my_trips_additonal_hide_passengers").split(
                        "[number]"
                      )[0] +
                      `${flightDetailsList?.paxInfo?.length - 1}` +
                      t("label_my_trips_additonal_hide_passengers").split(
                        "[number]"
                      )[1]
                    : t("label_my_trips_additonal_passengers").split(
                        "[number]"
                      )[0] +
                      `${flightDetailsList?.paxInfo?.length - 1}` +
                      t("label_my_trips_additonal_passengers").split(
                        "[number]"
                      )[1]}
                </div>
                {showAllPassengers ? (
                  <TGIcon icon="trip-downarrow-icon" fillColor="" />
                ) : (
                  <TGIcon icon="stop-arrow" fillColor="#684B9B" />
                )}
              </div>
            )}
          </div>
        </div>
        {!requestData?.pastTrip && (
          <div
            className={styles.manageStickyBtnMain}
            onClick={handleManageBooking}
          >
            <TGButton
              label={t("label_book_widget_manage_booking")}
              variant="primary"
              customClassName={styles.manageStickyBtn}
            />
          </div>
        )}
      </div>
    );
  }
};

export default MobFlightDetails;
