import React, { useEffect, useState } from "react";
import { t } from "i18next";
import TGButtonVariants from "../../../../../../../shared/tg-button-variants";
import styles from "../mobile-flight-details.module.scss";
import moment from "moment";
import { config } from "../../../../../../../config/global";

const MobCheckIn = ({ singleFlightDetails, flightDetailsList }: any) => {
  const [timeLeft, setTimeLeft] = useState<{
    days: any;
    hours: any;
    minutes: any;
  }>({ days: 0, hours: 0, minutes: 0 });
  const [isCheckin, setIsCheckin] = useState(false);

  useEffect(() => {
    const deadlineUTC = moment.utc(
      singleFlightDetails?.departureDateTimeUtc,
      "DD-MM-YY HH:mm"
    );

    const startTime = deadlineUTC.clone().subtract(24, "hours");
    const endTime = deadlineUTC.clone().subtract(1, "hours");

    if (!deadlineUTC.isValid()) {
      return;
    }

    const calculateTimeLeft = () => {
      const nowUTC = moment().utc();
      const timeDifference = moment
        .duration(startTime.diff(nowUTC))
        .add(1, "minutes");
      const countDown = {
        days: Math.floor(timeDifference.asDays()),
        hours: timeDifference.hours(),
        minutes: timeDifference.minutes(),
      };
      if (nowUTC.isBefore(startTime)) {
        setTimeLeft(countDown);
      } else {
        setTimeLeft({
          days: "00",
          hours: "00",
          minutes: "00",
        });
      }
      setIsCheckin(
        nowUTC.isSameOrAfter(startTime) && nowUTC.isSameOrBefore(endTime)
      );
    };
    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [singleFlightDetails]);

  const handleCheckIn = () => {
    const checkinUrl =
      process.env.REACT_APP_TG_AMADEUS_URL + config.CHECKIN_API;
    const checkinQueryParams =
      "pnr=" + flightDetailsList?.pnr + "&lname=" + flightDetailsList?.lastName;
    const amadeusRedirectionUrl = checkinUrl + "?" + checkinQueryParams;
    window.open(amadeusRedirectionUrl, "_blank");
  };

  return (
    <div className={styles.mobileCheckInMain}>
      <div>
        <div className={styles.mobileCheckInOpen}>{t("label_check_in")}</div>
        <div
          className={styles.mobileCheckInTime}
        >{`${timeLeft.days}d: ${timeLeft.hours}h: ${timeLeft.minutes}m`}</div>
      </div>
      <TGButtonVariants
        label={t("button_checkin")}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="30px"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        bgColor="#684B9B"
        padding="10px 24px 10px 24px"
        textColor="#FFFFFF"
        fontFamily="Inter-Bold"
        fontSize="16px"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="160%"
        borderDisabled="0.791px solid #E3D4FE"
        bgColorDisabled="#E3D4FE"
        boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        bgColorHover="#684b9b"
        borderHover="0.791px solid #E3D4FE"
        boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        disabled={!isCheckin}
        onClick={handleCheckIn}
      />
    </div>
  );
};

export default MobCheckIn;
