import React, { useEffect, useState, useRef } from "react";
import { t } from "i18next";
import styles from "../upcoming-trips/upcoming-trip.module.scss";
import style from "../signed-user.module.scss";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import { Form, Modal, ModalBody, Spinner } from "react-bootstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import TGIcon from "../../../../../../shared/tg-icon";
import {
  myTripsDataRequest,
  resetPnrDetails,
  retrieveTripsRequest,
  setMyTripDestination,
} from "../../../../slice/myTripsSlice";
import travel from "../../../../../../../public/assets/tripTravel.svg";
import { FlightDetails } from "../flight-details";
import { useNavigate } from "react-router-dom";
import { deleteTripCardDataRequest } from "../../../../slice/deleteTripCardSlice";
import TGButtonVariants from "../../../../../../shared/tg-button-variants";
import TGModal from "../../../../../../shared/tg-modal";
import image from "../../../../../../../public/assets/DeleteTripImage.png";

export const PastTrip = (props: { sendData: any; handleTripType: any }) => {
  const { sendData, handleTripType } = props;
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNoTrips, setShowNoTrips] = useState(true);
  const IMAGE_API = process.env.REACT_APP_TG_IMAGE_SEARCH_URL;
  const [cardBorder, setCardBorder] = useState(true);
  const [showTripDetails, setShowTripDetails] = useState("");
  const [pnrRequest, setPnrRequest] = useState<any>();
  const [showDeleteCard, setShowDeleteCard] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [deletePnr, setDeletePnr] = useState("");
  const pastCardRef = useRef<(HTMLDivElement | null)[]>([]);

  const pastTripsResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips.myTripsDetails
  );
  const addTripResponse = useSelector(
    (state: RootState) => state?.osciReducer?.myTrips
  );
  const deleteTripsResponse = useSelector(
    (state: RootState) => state?.osciReducer?.deleteTripCard
  );

  useEffect(() => {
    dispatch(
      myTripsDataRequest({
        category: "past",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (pastTripsResponse?.pnrDetails?.length > 0) {
      setShowNoTrips(false);
    } else {
      setShowNoTrips(true);
    }
  }, [pastTripsResponse]);

  useEffect(() => {
    if (deleteTripsResponse.hasData == true) {
      dispatch(
        myTripsDataRequest({
          category: "past",
        })
      );
    }
  }, [deleteTripsResponse?.hasData]);

  useEffect(() => {
    if (addTripResponse.isAddTripSuccess == true) {
      dispatch(
        myTripsDataRequest({
          category: "past",
        })
      );
    }
  }, [addTripResponse?.isAddTripSuccess]);

  const showFlightDetails = (index: any, pnrData: any) => {
    dispatch(resetPnrDetails());
    setCardBorder(!cardBorder);
    setShowTripDetails(showTripDetails === index ? null : index);
    setInitialLoad(false);
    let requestData = {
      pnr: pnrData.pnr,
      lastName: pnrData.lastName,
      pastTrip: true,
      isUpcoming: false,
    };
    setPnrRequest(requestData);
    sendData(pnrData);
    handleTripType("Past");
  };

  const hideFlightDetails = (index: any) => {
    setCardBorder(!cardBorder);
    setShowTripDetails(showTripDetails === index ? null : index);
    const selectedEle = document.getElementById(index);
    selectedEle?.scrollIntoView({ behavior: "smooth", block: "start" });
    sendData({});
  };

  const handleMobileNavigate = (pnrData: any) => {
    dispatch(resetPnrDetails());
    const data =
      pnrData?.tripType == "MULTI_CITY"
        ? renderMultiCityAirportCode(pnrData)
        : pnrData.flightDetails?.[0]?.destination;
    dispatch(setMyTripDestination(data));
    let requestData = {
      pnr: pnrData.pnr,
      lastName: pnrData.lastName,
      pastTrip: true,
      isUpcoming: false,
    };
    navigate(
      `/${i18n.language}/my-trips-details/${
        pnrData?.tripType == "MULTI_CITY"
          ? renderMultiCityAirportCode(pnrData)?.replace(/ \/\/ /g, "-")
          : pnrData.flightDetails?.[0]?.destination
      }`,
      { state: { requestData, data: data } }
    );
  };

  const showDeletedTripData = (detail: any) => {
    setShowDeleteCard(true);
    setDeletePnr(detail);
  };
  const handleCloseModal = () => {
    setShowDeleteCard(false);
    setDeletePnr("");
  };
  const deleteTripCardHandler = () => {
    setShowDeleteCard(false);
    dispatch(deleteTripCardDataRequest(deletePnr));
    setDeletePnr("");
  };

  const renderMultiCityAirportCode = (item: any) => {
    //contains flightRef of connecting flights
    const flight = [];
    //Contains segmentGroups Length
    const flightLength = [];
    let content = "";
    //Contains segmentGroups last flightRef
    let lastArray = [];

    //Iteration for to push elements in an flight array and flightLength array
    for (let i: number = 0; i < item?.segmentGroups.length; i++) {
      flightLength.push(
        JSON.parse(item?.segmentGroups[i].connectionSegments).length + 1
      );
      for (
        let j: number = 0;
        j < JSON.parse(item?.segmentGroups[i]?.connectionSegments).length;
        j++
      ) {
        flight.push(JSON.parse(item?.segmentGroups[i]?.connectionSegments)[j]);
      }
    }

    //Iteration for to push elements in an lastArray
    for (let last: number = 0; last < item?.segmentGroups.length; last++) {
      lastArray.push(
        JSON.parse(item?.segmentGroups[last].connectionSegments).reverse()[0]
      );
    }
    let startVal = 0;
    let count = 0;

    //condition for checking flightDetails flightRef number with flight array elements
    for (let k: number = 0; k < item?.flightDetails.length; k++) {
      if (Number(item?.flightDetails[k]?.flightRef) === flight[count]) {
        if (lastArray[startVal] === Number(item?.flightDetails[k]?.flightRef)) {
          if (
            item?.flightDetails[k]?.flightRef ===
            item?.flightDetails.length.toString()
          ) {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
          } else {
            content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode} // `;
          }
          startVal++;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-`;
        }

        count++;
      } else {
        if (
          item?.flightDetails[k]?.flightRef ===
          item?.flightDetails.length.toString()
        ) {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode}`;
        } else {
          content += `${item?.flightDetails[k]?.departureCode}-${item?.flightDetails[k]?.arrivalCode} // `;
        }
      }
    }
    return content;
  };

  const renderMultiCityAirportName = (flights: any): any => {
    const result: any = [];
    flights.forEach((flight: any, index: any) => {
      if (result[result.length - 1] !== flight.departureAirportName) {
        result.push(flight.departureAirportName);
      }
      if (
        index === flights.length - 1 ||
        result[result.length - 1] !== flight.arrivalAirportName
      ) {
        result.push(flight.arrivalAirportName);
      }
    });
    return result;
  };

  const handleLazyLoad = (cardId: any) => {
    const pnr = pastTripsResponse?.pnrDetails[cardId]?.pnr;
    const lastName = pastTripsResponse?.pnrDetails[cardId]?.lastName;
    const pastTrip = true;
    dispatch(retrieveTripsRequest({ pnr, lastName, pastTrip, cardId }));
  };

  useEffect(() => {
    if (!showNoTrips) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index: any = entry.target.getAttribute("data-index");
              if (
                pastTripsResponse?.pnrDetails[index]?.flightDetails?.length ===
                0
              ) {
                handleLazyLoad(index);
              }
            }
          });
        },
        { threshold: 0.1 }
      );

      pastCardRef.current.forEach((card) => {
        if (card) {
          observer.observe(card);
        }
      });

      return () => {
        pastCardRef.current.forEach((card) => {
          if (card) {
            observer.unobserve(card);
          }
        });
      };
    }
  }, [pastTripsResponse, pastCardRef, showNoTrips]);

  useEffect(() => {
    if (
      initialLoad &&
      pastTripsResponse?.pnrDetails?.length > 0 &&
      window.innerWidth > 767
    ) {
      showFlightDetails(0, pastTripsResponse?.pnrDetails[0]);
    }
  }, [pastTripsResponse]);

  if (!addTripResponse?.myTripsDetails?.hasOwnProperty("memberId")) {
    return (
      <div>
        <Modal
          show={true}
          centered
          className={`${styles["my-trips-loader-container"]} my-trips-main-loading-spinner`}
        >
          <ModalBody
            style={{
              width: "max-content",
              margin: "auto",
              background: "unset",
              border: "unset",
            }}
          >
            <Spinner animation="border" variant="primary" /> <br />
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <>
        {showNoTrips ? (
          <div className={style.noTripIcon}>
            <div
              className={`${
                isDesktopView
                  ? style.imageContainer
                  : style.imageContainerMobile
              }`}
            >
              <TGIcon icon="past-trip-icon" fillColor="none" size="" />
              <p className={style.imgText}>{t("label_no_past_trips")}</p>
            </div>
          </div>
        ) : (
          <div className={styles.upcomingTripCardContainerScroll}>
            {pastTripsResponse?.pnrDetails?.map((resp: any, index: any) => {
              const loading =
                addTripResponse?.isLoading && resp?.flightDetails?.length === 0;
              return (
                <div
                  className={styles.upcomingTripCardContainer}
                  id={index}
                  key={index}
                  ref={(el) => (pastCardRef.current[index] = el)}
                  data-index={index}
                >
                  {/* Flight Card Section */}
                  <div
                    className={`${styles["upcoming-trip-background-card"]} ${
                      pastTripsResponse?.pnrDetails?.length > 0
                        ? showTripDetails !== index
                          ? styles["show-trip-border"]
                          : styles["hide-trip-border"]
                        : styles["hide-trip-border"]
                    }`}
                    style={{
                      background: `linear-gradient(26deg, rgba(33, 9, 67, 0.90) 4.24%, rgba(33, 9, 67, 0.00) 90.99%), url(${
                        resp?.tripType === "MULTI_CITY"
                          ? "../assets/multiCity.svg"
                          : IMAGE_API +
                            resp?.flightDetails[resp.flightDetails.length - 1]
                              ?.arrivalCode +
                            ".jpg"
                      }) lightgray 0px -273.514px / 100% 205.405% no-repeat`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div
                      className={styles.cardContentCloseIcon}
                      onClick={() => {
                        showDeletedTripData(resp?.pnr);
                      }}
                    >
                      <TGIcon
                        icon="close-icon"
                        size={""}
                        fillColor={"#FFFFFF"}
                      />
                    </div>
                    <img
                      src={travel}
                      alt="travel"
                      className={styles.pastTripImgMain}
                    />
                    {loading ? (
                      <div className={styles.shimmerCardContent}>
                        <span
                          className={`${styles.shimmer} ${styles.shimmerTripToHeader}`}
                        ></span>
                        <span
                          className={`${styles.shimmer} ${styles.shimmerDestinationHeader}`}
                        ></span>
                        <div className={styles.dateContainer}>
                          <span
                            className={`${styles.shimmer} ${styles.shimmerDate}`}
                          ></span>
                          <span className={styles.bookingRefNo}>
                            {t("label_booking_ref")}: {resp.pnr}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.cardContent}>
                        <span className={styles.tripToHeader}>
                          <span className={styles.tripToHeaderSub}>
                            {resp?.tripType == "ROUND"
                              ? t("label_book_widget_round_trip")
                              : resp?.tripType == "MULTI_CITY"
                              ? t("label_trip_multicity")
                              : t("label_book_widget_one_way")}
                          </span>
                          <span>{t("label_trip_to")}</span>
                        </span>
                        <span className={styles.destinationHeader}>
                          {resp?.tripType == "MULTI_CITY"
                            ? renderMultiCityAirportCode(resp)
                            : resp.flightDetails?.[0]?.destination}
                        </span>
                        <div className={styles.dateContainer}>
                          <span className={styles.date}>
                            {resp?.tripType !== "ONE_WAY"
                              ? `${moment(
                                  resp?.flightDetails[0]?.depatureDate,
                                  "DD-MM-YY HH:mm"
                                ).format("DD MMM YYYY")} - ${moment(
                                  resp?.flightDetails[
                                    resp.flightDetails.length - 1
                                  ]?.depatureDate,
                                  "DD-MM-YY HH:mm"
                                ).format("DD MMM YYYY")}`
                              : `${moment(
                                  resp?.flightDetails[0]?.depatureDate,
                                  "DD-MM-YY HH:mm"
                                ).format("DD MMM YYYY, HH:mm")}`}
                          </span>
                          <span className={styles.bookingRefNo}>
                            {t("label_booking_ref")}: {resp.pnr}
                          </span>
                        </div>
                      </div>
                    )}
                    <div
                      className={`${styles["trip-detail"]} ${
                        cardBorder
                          ? styles.addPaddingBottom
                          : styles.removePaddingBottom
                      }`}
                    >
                      <div className={styles["trip-detail-content"]}>
                        <div className={styles["trip-detail-content-inner"]}>
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripType}`}
                            ></div>
                          ) : (
                            <div className={styles["trip-type"]}>
                              {resp?.tripType == "ROUND"
                                ? t("label_book_widget_round_trip")
                                : resp?.tripType == "MULTI_CITY"
                                ? t("label_trip_multicity")
                                : t("label_book_widget_one_way")}
                            </div>
                          )}
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripLocation}`}
                            ></div>
                          ) : (
                            <div className={styles["trip-location"]}>
                              {resp?.tripType == "ROUND"
                                ? `${resp?.flightDetails[0]?.origin} - ${resp?.flightDetails[0]?.destination}`
                                : resp?.tripType === "MULTI_CITY"
                                ? renderMultiCityAirportCode(resp)
                                : `${resp?.flightDetails[0]?.origin} - ${
                                    resp?.flightDetails[
                                      resp.flightDetails.length - 1
                                    ]?.destination
                                  }`}
                            </div>
                          )}
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripAirportName}`}
                            ></div>
                          ) : (
                            <div className={styles["trip-airportName"]}>
                              {resp?.tripType === "ONE_WAY" &&
                                `${resp?.flightDetails[0]?.arrivalAirportName} - ${resp?.flightDetails[0]?.departureAirportName}`}
                              {resp?.tripType === "ROUND" &&
                                `${
                                  resp?.flightDetails[0]?.arrivalAirportName
                                } - ${
                                  resp?.flightDetails[
                                    resp.flightDetails.length - 1
                                  ]?.arrivalAirportName
                                }`}
                              {resp?.tripType === "MULTI_CITY" &&
                                resp?.flightDetails &&
                                renderMultiCityAirportName(
                                  resp?.flightDetails
                                )?.map((item: any, index: any, data: any) => (
                                  <span>
                                    {item}{" "}
                                    {data.length - 1 !== index ? "-" : ""}{" "}
                                  </span>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                      {!isDesktopView && (
                        <div
                          className={styles["trip-detail-btn-view"]}
                          onClick={() => handleMobileNavigate(resp)}
                        >
                          {t("label_view_detail")}
                        </div>
                      )}
                      {showTripDetails !== index && isDesktopView && (
                        <div className={`${styles["trip-detail-button"]}`}>
                          {loading ? (
                            <div
                              className={`${styles.shimmer} ${styles.shimmerTripDetailShow}`}
                            ></div>
                          ) : (
                            <div
                              className={styles["trip-detail-btn-content"]}
                              onClick={() => {
                                setInitialLoad(false);
                                showFlightDetails(index, resp);
                              }}
                            >
                              <span className={styles["trip-detail-show"]}>
                                {t("label_my_trips_show_trips")}
                              </span>
                              <TGIcon
                                icon="trip-uparrow-icon"
                                size={""}
                                fillColor={""}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Flight Details Section */}
                  {showTripDetails === index && (
                    <div className={styles.flightDetailsSection}>
                      <div className={styles.flightDetailsContent}>
                        <div className={styles.flightDetails}>
                          <div className={styles.flightDetailsHeader}>
                            <span className={styles.flightDetailsHeaderText}>
                              {t("label_redemption_flight_details")}
                            </span>
                          </div>
                          {isDesktopView && (
                            <FlightDetails
                              flightDetails={[resp, index]}
                              selectedTab="PT"
                              requestData={pnrRequest}
                              parentIndex={index}
                            />
                          )}
                        </div>
                        <div className={styles.hideTripContainer}>
                          <div className={styles.hideTripButtonContent}>
                            <div
                              className={styles["hide-trip-button"]}
                              onClick={() => {
                                setInitialLoad(false);
                                hideFlightDetails(index);
                              }}
                            >
                              <span className={styles["hide-trip-button-text"]}>
                                {t("label_my_trips_hide_trips")}
                              </span>
                              <TGIcon
                                icon="trip-downarrow-icon"
                                size={""}
                                fillColor={""}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <TGModal
                    show={showDeleteCard}
                    handleClose={handleCloseModal}
                    centered={true}
                    customModalClass={styles["trip-card-model"]}
                    customOffCanvasClass={styles["trip-card-offcanvas"]}
                    backdropClassName={styles["trip-card-backdrop"]}
                  >
                    <div className={styles.tcModalFlex}>
                      <div className={styles.tcModal}>
                        <img
                          className={styles.deletetripimage}
                          src={image}
                          alt="deletetripimage"
                        />
                        <div className={styles.modalContent}>
                          <span className={styles.modalHeading}>
                            {t("delete_trip_heading")}
                          </span>
                          <div className={styles.modalDescription}>
                            {t("delete_trip_description")}
                          </div>
                        </div>
                      </div>
                      <div className={styles.dbuttonContainer}>
                        <TGButtonVariants
                          label={t("button_label_keep")}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="30px"
                          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                          bgColor="#F5EFFF"
                          bgColorHover="#F5EFFF"
                          padding="8px 24px"
                          textColor="#381B6B"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="160%"
                          height="50px"
                          onClick={handleCloseModal}
                          borderHover="none"
                          width={isDesktopView ? "170px" : "50%"}
                        />
                        <TGButtonVariants
                          label={t("button_label_delete")}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="30px"
                          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                          bgColor="#381B6B"
                          bgColorHover="#381B6B"
                          padding="8px 24px"
                          textColor="#FFFFFF"
                          fontFamily="Inter"
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="700"
                          lineHeight="160%"
                          height="50px"
                          onClick={deleteTripCardHandler}
                          borderHover="none"
                          width={isDesktopView ? "170px" : "50%"}
                        />
                      </div>
                    </div>
                  </TGModal>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }
};
